const colors = {
    //ALL NEW COLORS
    carbon: '#3F3F3F',
    //uigrays
    uiGrayDarker: '#E3E3E3',
    uiGrayDark: '#F6F6F6',
    uiGrayDefault: '#FAFAFA',
    uiGrayPopupBorder: '#CBCBCB',
    //accent
    accentActive: '#1D42B2',
    accentDefault: '#295EFF',
    accentHover: '#2350D9',
    //black and whites
    black: '#39464E',
    // borders
    borders: 'rgba(57, 70, 78, 0.2)',
    //text
    textDefault: 'rgba(63, 63, 63, 1)',
    textSubtle: 'rgba(63, 63, 63, 0.75)',
    textDisabled: 'rgba(63, 63, 63, 0.3)',
    textHyperlink: '#295EFF',
    //background
    bgDefault: 'rgba(63, 63, 63, 1)',
    bgOverlayHeavy: 'rgba(63, 63, 63, 0.75)',
    bgOverlayLight: 'rgba(63, 63, 63, 0.3)',
    bgSubtle: 'rgba(63, 63, 63, 0.15)',
    bgActive: 'rgba(63, 63, 63, 0.08)',
    bgDisabled: 'rgba(63, 63, 63, 0.05)',
    bgHover: 'rgba(63, 63, 63, 0.04)',
    //
    error: '#F21818',
    success: '#00A344',
    warning: '#FF8B38',
    info: 'rgba(67, 124, 255, 0.13)',
    // sentiment
    positive: '#00A344',
    neutral: '#DADDE0',
    negative: '#F21818',

    // emotion
    anger: '#992F28',
    disgust: '#99790F',
    fear: '#E8483C',
    joy: '#7FD15E',
    sadness: '#FFC3BF',
    surprise: '#FAC519',

    // gender
    female: '#C73C7A',
    male: '#285BB8',

    icons: '#295EFF',

    //TODO fix this on calendarIcon
    primaryBlue100: '#295EFF',
    //TODO: Fix this for filters clear button
    secondaryBlue100: '#295EFF',
};

export default colors;
