
//Entry configuration
import theme from 'hsi/themes/falcon';
import config from 'hsi/configurations/falcon/config';

//Utils
import entryFactory from 'hsi/utils/entry-factory';

//Init the app
entryFactory('falcon', config, theme, {});
