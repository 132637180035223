import baseConfig from 'hsi/configurations/_axiomy/config';
import {AppConfigType} from 'hsi/types/config';

//Modify config here as required
const config: AppConfigType = {
    ...baseConfig,
    appName: 'Listen',
    appSource: 'FALCON',
    links: {
        ...baseConfig.links,
        searchHelp:
            'https://help.falcon.io/hc/en-us/articles/360020466997-Building-Advanced-Queries-in-Listen',
        helpCenter:
            'https://social-media-management-help.brandwatch.com/hc/en-us/categories/4404905049373-Listen',
        feedbackLink:
            'https://docs.google.com/forms/d/e/1FAIpQLSecpE1lLw1TcLK1SrRbd5nrIfWkc8thXxksftWcW5Mvn2ws8w/viewform',
        alertsLearnMore:
            'https://help.falcon.io/hc/en-us/articles/360020653218-Creating-and-Managing-Listen-Email-Alerts',

        //card info popup ctas
        dashboardInfoTotalVolumeCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoMentionsHistoryCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoHeatmapCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoWordCloudCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoBenchmarkCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoSentimentVolumeCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoSentimentHistoryCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoEmotionVolumeCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoEmotionHistoryCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopSharedURLsCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopSitesCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopAuthorsCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopHashtagsCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopInterestsCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopLanguagesCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoGenderCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoGeographyCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoTopicWheelCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoMentionsCTA:
            'https://social-media-management-help.brandwatch.com/hc/en-us/articles/4555758894877-Viewing-and-Analyzing-Listen-Searches',
        dashboardInfoNetSentimentCTA: undefined,
    },
    showHelp: false,
    themeColors: {
        ...baseConfig.themeColors,
        colorScaleDefault: '#F0F0EF',
        primaryColor: '#295EFF',
        queryColors: [
            '#8546E8',
            '#F63DD2',
            '#C82D1D',
            '#2AB300',
            '#9F6D2C',
            '#F69224',
            '#F7C400',
            '#239FB5',
            '#E35172',
            '#B3CE00',
        ],
        topicType: {
            hashtag: '#7971E6',
            place: '#ED5FD1',
            organisation: '#5CE3E6',
            person: '#3CC78B',
            word: '#E6AF3A',
            phrase: '#AB7137',
        },
        gender: {male: '#285BB8', female: '#C73C7A', unknown: '#dadde0'},
        heatmap: [
            'rgba(121, 113, 230, 0.2)',
            'rgba(121, 113, 230, 0.6)',
            'rgba(121, 113, 230, 0.8)',
            'rgba(121, 113, 230, 1)',
        ],
        sentiment: {positive: '#00A344', negative: '#F21818', neutral: '#cecece'},
        volume: {low: '#DADDE0', mid: '#7971E6', high: '#1D42B2'},
        trending: {low: '#DADDE0', mid: '#E6AF3A', high: '#ED5FD1'},
        random: ['#7971E6', '#ED5FD1', '#CD2418', '#57B332', '#E6AF3A', '#AB7137'],
        none: ['#3F3F3F'],
        default: ['#d3f1ff', '#94d2f1', '#0087ca', '#63c9fb', '#239edb'],
        emotion: {
            anger: '#B34F36',
            disgust: '#BF8F60',
            fear: '#FF8533',
            joy: '#F288E8',
            sadness: '#6DA158',
            surprise: '#C6E645',
        },
        sparkline: '#295EFF',
        success: '#00A344',
        error: '#F21818',
        warning: '#FF8B38',
        choroplethMapColors: {
            render: [
                'rgba(121, 113, 230, 0.2)',
                'rgba(121, 113, 230, 0.6)',
                'rgba(121, 113, 230, 0.8)',
                'rgba(121, 113, 230, 1)',
            ],
            hover: '#ED5FD1',
            pressed: '#ED5FD1',
        },
        heatmapColors: [
            'rgba(121, 113, 230, 0.2)',
            'rgba(121, 113, 230, 0.6)',
            'rgba(121, 113, 230, 0.8)',
            'rgba(121, 113, 230, 1)',
        ],
    },
    editorConfig: {
        ...baseConfig.editorConfig,
        fontFamily: "'Open Sans', sans-serif",
    },
    exportType: 'falcon',
    lineChart: {
        leftPadding: -15,
        hasSideLabel: false,
        tickLine: true,
        axisLine: true,
        tickMargin: 12,
    },
    hasBrandwatchLabel: false,
} as const;

export default config;
