import colors from './colors';
//Classes
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

//Other
import brandwatchTheme from 'hsi/themes/bw';
import {Colors} from 'hsi/types/theme';

//The theme
const theme = new ThemeBuilder(
    'FALCON',
    true,
    {
        colors: function () {
            return (
                ThemeBuilder.nullMerge<Partial<Colors>>(colors, this._getExtendedValue('colors')) ||
                {}
            );
        },
        font: () => ({
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese',
            name: "'Roboto', sans-serif",
            light: 300,
            bold: 700,
            semiBold: 500,
            regular: 400,
        }),
        fonts: function ({font}) {
            return (
                ThemeBuilder.nullMerge(
                    {
                        display2Strong: {
                            fontSize: '39px',
                            fontWeight: font.semiBold,
                        },
                        display1Normal: {
                            fontWeight: font.light,
                        },
                        display1Strong: {
                            fontWeight: font.semiBold,
                        },
                        headlineNormal: {
                            fontWeight: font.light,
                        },
                        headlineStrong: {
                            fontWeight: font.semiBold,
                        },
                        headtitleNormal: {
                            fontWeight: font.regular,
                        },
                        headtitleStrong: {
                            fontWeight: font.semiBold,
                        },
                        bodyLargeNormal: {
                            fontWeight: font.regular,
                        },
                        bodyLargeStrong: {
                            fontWeight: font.bold,
                        },
                        bodyNormal: {
                            fontWeight: font.regular,
                        },
                        bodyStrong: {
                            fontWeight: font.bold,
                        },
                        bodySmall: {
                            fontWeight: font.regular,
                        },
                        bodySmallStrong: {
                            fontWeight: font.bold,
                        },
                        buttonLarge: {
                            fontWeight: font.bold,
                        },
                        buttonNormal: {
                            fontWeight: font.bold,
                        },
                        labelStrong: {
                            fontWeight: font.bold,
                        },
                        labelNormal: {
                            fontWeight: font.regular,
                        },
                    },
                    this._getExtendedValue('fonts'),
                ) || {}
            );
        },
        globals: function ({mixin}) {
            return ThemeBuilder.nullMerge(
                {
                    a: {...mixin.linkColor},
                },
                this._getExtendedValue('globals'),
            );
        },
        overrides: function ({colors}) {
            return ThemeBuilder.nullMerge(
                {
                    /********     BUTTON    ********/
                    MuiButton: {
                        contained: {
                            color: colors.textDefault,
                            borderRadius: '3px',
                            transition: 'none',
                            '&:hover': {
                                backgroundColor: colors.bgHover,
                                color: colors.textDefault,
                                border: '1px solid transparent',
                            },
                            '&:active': {
                                backgroundColor: colors.bgActive,
                                color: colors.textDefault,
                            },
                            '&:focus': {
                                backgroundColor: colors.bgActive,
                                color: colors.textDefault,
                            },
                            '&.Mui-disabled': {
                                backgroundColor: colors.bgDisabled,
                                border: '1px solid transparent',
                            },
                        },
                        containedSecondary: {
                            color: colors.textDefault,
                            borderRadius: '3px',
                            '&:hover': {
                                backgroundColor: colors.bgHover,
                                color: colors.textDefault,
                            },
                            '&:active': {
                                backgroundColor: colors.bgActive,
                                color: colors.textDefault,
                            },
                            '&:focus': {
                                backgroundColor: colors.bgActive,
                                color: colors.textDefault,
                            },
                            '&.Mui-disabled': {
                                backgroundColor: colors.bgDisabled,
                                border: '1px solid transparent',
                            },
                        },
                        text: {
                            color: colors.textDefault,
                            '&:hover': {
                                color: colors.textDefault,
                                backgroundColor: colors.bgHover,
                            },
                            '&:active': {
                                backgroundColor: colors.bgActive,
                            },
                            '&:focus': {
                                backgroundColor: colors.bgActive,
                            },
                        },
                    },
                    /********   BUTTON GROUP    *******/
                    MuiButtonGroup: {
                        grouped: {
                            color: colors.white,
                            '&:hover': {
                                color: colors.white,
                            },
                        },
                    },
                },
                this._getExtendedValue('overrides'),
            );
        },
    },
    //Extends
    brandwatchTheme,
);

export default theme;
